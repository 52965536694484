import React from 'react'
import { useTranslation } from 'react-i18next'
import { IResponseCodes } from '../../interfaces/restInterfaces'
import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'

interface IRestStatusCodeTableProps {
  statusCodes: IResponseCodes[]
}

const RestStatusCodeTable: React.FC<IRestStatusCodeTableProps> = ({
  statusCodes,
}) => {
  const { t } = useTranslation()

  const mergedStatusCodes = [
    {
      code: '400',
      description: t('statusCodeTable.400'),
    },
    {
      code: '401',
      description: t('statusCodeTable.401'),
    },
    {
      code: '403',
      description: t('statusCodeTable.403'),
    },
    {
      code: '429',
      description: t('statusCodeTable.429'),
    },
    ...statusCodes,
  ].sort((a, b) => {
    if (a.code < b.code) {
      return -1
    } else if (a.code > b.code) {
      return 1
    }
    return 0
  })

  const justCodes = mergedStatusCodes.map((code) => code.code)
  const uniqueStatusCodes = mergedStatusCodes.filter(
    ({ code }, i) => !justCodes.includes(code, i + 1)
  )

  const statusCodeStyle = (code: string): JSX.Element => {
    if (code[0] === '2') {
      return <td className="status-code ok">{code}</td>
    } else {
      return <td className="status-code error">{code}</td>
    }
  }

  return (
    <section className="md-data-table status-codes-container">
      <div className="md-data-table__wrapper">
        <Accordion
          multipleVisible
          initialActive={[0]}
          initialActiveFocus={false}
        >
          <AccordionGroup>
            <AccordionHeader className="accordion-header">
              <h2> {t('generalDocumentation.responseCodes')}</h2>
            </AccordionHeader>
            <AccordionContent>
              <table className="status-code-table">
                <tbody className="md-data-table__tbody">
                  <tr className="status-code-header-row">
                    <th className="status-code-header">
                      {t('generalDocumentation.codeTableHeader')}
                    </th>
                    <th className="status-code-header">
                      {t('generalDocumentation.descriptionTableHeader')}
                    </th>
                  </tr>
                  {uniqueStatusCodes.map((code, index) => {
                    return (
                      <tr className="status-code-row" key={index}>
                        {statusCodeStyle(code.code)}
                        <td className="status-code-description">
                          {code.description}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </AccordionContent>
          </AccordionGroup>
        </Accordion>
      </div>
    </section>
  )
}

export default RestStatusCodeTable
