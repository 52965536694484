import { Select, SelectOption } from '@momentum-ui/react'
import { Language } from 'prism-react-renderer'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IRestDocumentation } from '../../../interfaces/restInterfaces'
import ApiRoute from '../../ApiRoute'
import CodeSnippet from '../../CodeSnippet'

interface SampleContainerProps {
  apiDetails: IRestDocumentation
}

const SampleContainer: React.FC<SampleContainerProps> = ({ apiDetails }) => {
  const { responseExample, httpVerb, endpoint, snippets } = apiDetails
  const [selectedSnippetLanguage, setSelectedSnippetLanguage] = useState('curl')
  const { t } = useTranslation()

  const swapInCorrectGatewayDomain = (code: string): string => {
    return code.replace(/http.*?\/\/.*?\//, `${process.env.GATEWAY_URL}/`)
  }

  const determineLanguage = (): Language => {
    if (selectedSnippetLanguage === 'curl') {
      return 'bash'
    } else if (selectedSnippetLanguage === 'csharp') {
      return 'clike'
    } else {
      return selectedSnippetLanguage as Language
    }
  }

  return (
    <div className="sample-container">
      <div className="snippet-container">
        <CodeSnippet
          canCopy
          codeString={swapInCorrectGatewayDomain(
            snippets[selectedSnippetLanguage]
          )}
          language={determineLanguage()}
          headerContentsLeftSide={
            <ApiRoute badgeType={httpVerb} endpoint={endpoint} />
          }
          headerContentsRightSide={
            snippets && (
              <Select
                defaultValue="cURL"
                onSelect={(val: any): void => {
                  setSelectedSnippetLanguage(val[0].value)
                }}
                className="snippet-lang-select"
              >
                {Object.keys(snippets).map((language) => (
                  <SelectOption
                    value={language}
                    label={language === 'curl' ? 'cURL' : language}
                    key={language}
                  />
                ))}
              </Select>
            )
          }
          displayLineNumbers
        />
      </div>
      <div className="example-response-container">
        <CodeSnippet
          canCopy
          codeString={
            responseExample ? JSON.stringify(responseExample, null, 2) : ' '
          }
          language="json"
          displayLineNumbers={responseExample != null}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.response')}</span>
          }
        />
      </div>
    </div>
  )
}

export default SampleContainer
