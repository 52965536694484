import {
  IRestDocumentation,
  IRestParameters,
} from '../interfaces/restInterfaces'
import { makeArrayTypeString } from './general.utils'

export interface IBucketedParams {
  query: IRestParameters[]
  path: IRestParameters[]
  header: IRestParameters[]
}

export const assembleCurlStatement = (
  apiDetails: IRestDocumentation,
  token?: string
): string => {
  const {
    endpoint,
    parameters,
    httpVerb,
    requestBody,
    requestExample,
  } = apiDetails
  let updatedPath = endpoint
  if (parameters?.length) {
    parameters?.forEach((param) => {
      if (param.in === 'path' && param.value) {
        updatedPath = updatedPath.replace(`{${param.name}}`, param.value)
      }

      if (param.in === 'query') {
        const separator = updatedPath.includes('?') ? '&' : '?'
        updatedPath += `${separator}${param.name}=${param.value || ''}`
      }
    })
  }

  let curlStatement = `curl --location --request ${httpVerb.toUpperCase()} '${
    process.env.GATEWAY_URL
  }${updatedPath}' \\\r\n--header 'Authorization: Bearer ${
    token || 'YOUR_AUTHORIZATION_TOKEN'
  }' `

  if (requestBody) {
    let newBody
    const contentType = requestBody.contentType || 'application/json'
    if (typeof requestBody.value === 'string') {
      // if already json string, parse for the case where the json string contains formatting carriage returns (\n) and such
      newBody = JSON.parse(requestBody.value)
    } else {
      newBody = requestBody?.value || requestExample || '{}'
    }

    curlStatement += `\\\r\n--header 'Content-Type: ${contentType}' \\\r\n--data-raw '${JSON.stringify(
      newBody,
      null,
      2
    )}'`
  }

  return curlStatement
}

export const bucketParameters = (
  parameters: IRestParameters[] | undefined
): IBucketedParams | undefined => {
  if (parameters) {
    const query = []
    const path = []
    const header = []
    for (const parameter of parameters) {
      if (parameter.in === 'query') {
        query.push(parameter)
      } else if (parameter.in === 'path') {
        path.push(parameter)
      } else if (parameter.in === 'header') {
        header.push(parameter)
      }
    }
    return {
      query,
      path,
      header,
    }
  }

  return undefined
}

export const prepareEndpoint = (
  endpoint: string,
  bucketedParameters: Record<string, any> | undefined
): string => {
  const pathsContainingBraces = endpoint.match(/{([^}]+)}/g)
  if (pathsContainingBraces && bucketedParameters && bucketedParameters.path) {
    let result = endpoint
    for (const path of pathsContainingBraces) {
      const pathWithNoBrackets = path.replace('{', '').replace('}', '')
      for (const parameter of bucketedParameters.path) {
        if (parameter.name === pathWithNoBrackets && parameter.value) {
          result = result.replace(path, parameter.value)
        }
      }
    }
    return result
  }
  return endpoint
}

export const prepareQueryParameters = (
  bucketedParameters: Record<string, any> | undefined
): Record<string, any> => {
  if (bucketedParameters && bucketedParameters.query) {
    const result: Record<string, any> = {}
    bucketedParameters.query.forEach((parameter: Record<string, any>) => {
      const preparedValue = parameter.value
      if (preparedValue) {
        result[parameter.name] = preparedValue
      }
    })
    return result
  }
  return {}
}

export const sortParameters = (
  params: IRestParameters[] | undefined
): IRestParameters[] | undefined => {
  if (params?.length) {
    return params.sort((a, b) => {
      if (a.required && b.required) {
        return 0
      } else if (a.required) {
        return -1
      } else if (b.required) {
        return 1
      } else {
        return 0
      }
    })
  }
}

export const determineParamType = (param: IRestParameters): string => {
  return (
    makeArrayTypeString(param.schema?.items?.format) ||
    param.schema?.format ||
    makeArrayTypeString(param.schema?.items?.type) ||
    param.schema?.type
  )
}
