import { Button, Input } from '@momentum-ui/react'
import { AxiosResponse } from 'axios'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { API_TEST_RUN } from '../../../constants/metrics'
import {
  IRestDocumentation,
  IRestParameters,
} from '../../../interfaces/restInterfaces'
import httpService from '../../../services/httpService'
import MetricsService from '../../../services/metricsService'
import {
  assembleCurlStatement,
  bucketParameters,
  determineParamType,
  prepareEndpoint,
  prepareQueryParameters,
  sortParameters,
} from '../../../utils/tryout.utils'
import BearerTokenInput from '../../BearerToken'
import CodeSnippet from '../../CodeSnippet'
import CodeSnippetHeader from '../../CodeSnippet/CodeSnippetHeader'
import ResponseCode from '../../ResponseCode'

interface IRestTryOutProps {
  api: IRestDocumentation
  name: string
}

const RestTryOut: React.FC<IRestTryOutProps> = ({ api, name }) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  const [tokenFromInput, setTokenFromInput] = useState('')
  const [canCopyCurl, setCanCopyCurl] = useState(true)
  const [sendingRequest, setSendingRequest] = useState(false)
  const [responseFromApi, setResponseFromApi] = useState(
    {} as AxiosResponse | Record<string, any>
  )

  api.parameters = sortParameters(api.parameters)

  const [apiDetails, setApiDetails] = useState(api)
  const [curlStatement, setCurlStatement] = useState(() =>
    assembleCurlStatement(api, tokenFromInput)
  )

  const validateRequestBodyJson = (
    requestBody: string,
    apiDetails: IRestDocumentation,
    token: string
  ): void => {
    try {
      JSON.parse(requestBody)
      // only set the curl statement when JSON is valid
      setCurlStatement(assembleCurlStatement(apiDetails, token))
      setCanCopyCurl(true)
    } catch (e) {
      // Must be valid JSON in order to copy
      setCanCopyCurl(false)
    }
  }

  const handleAuthTokenInput = (token: string): void => {
    if (token !== tokenFromInput) {
      setTokenFromInput(token)
      if (apiDetails.requestBody) {
        validateRequestBodyJson(
          apiDetails.requestBody as any,
          apiDetails,
          token
        )
      } else {
        setCurlStatement(assembleCurlStatement(apiDetails, token))
      }
    }
  }

  const getUrl = ()=>{
    if(localStorage.getItem('bff_base_url')){
      const url = localStorage.getItem('bff_base_url')
      console.log('this is url', url)
      return url
    } else {
      return process.env.GATSBY_BFF_BASE_URL
    }
  }

  const runHttpRequest = async (): Promise<void> => {
    const { requestBody, parameters, httpVerb, endpoint } = apiDetails
    const bucketedParameters = bucketParameters(parameters)
    const preparedQueryParameters = prepareQueryParameters(bucketedParameters)
    const preparedEndpoint = prepareEndpoint(endpoint, bucketedParameters)
    const preparedUrl = `${getUrl()}proxy${preparedEndpoint}`

    const headers = tokenFromInput
      ? { Authorization: `Bearer ${tokenFromInput}` }
      : undefined

    const headersForRequestsWithBodies = {
      ...headers,
      'Content-Type': requestBody?.contentType || 'application/json',
    }
    const jsonSyntaxErrorResponse = {
      status: 400,
      data: {
        error: {
          key: '400',
          message: [
            {
              description: 'JSON input is invalid',
            },
          ],
        },
      },
    }

    let theResponse: { [key: string]: any } = {}

    try {
      setSendingRequest(true)

      switch (httpVerb.toLowerCase()) {
        case 'get':
          theResponse = await httpService.get(preparedUrl, {
            params: preparedQueryParameters,
            headers,
          })
          break
        case 'delete':
          theResponse = await httpService.delete(preparedUrl, {
            params: preparedQueryParameters,
            headers,
          })
          break
        case 'patch':
        case 'post':
        case 'put':
          if (requestBody) {
            type pVerb = 'patch' | 'post' | 'put'
            const theVerb = httpVerb.toLowerCase() as pVerb
            if (!requestBody.value) {
              theResponse = await httpService[theVerb](
                preparedUrl,
                {},
                { headers: headersForRequestsWithBodies }
              )
            } else {
              try {
                const jsonConvertedData = JSON.parse(requestBody.value)
                theResponse = await httpService[theVerb](
                  preparedUrl,
                  jsonConvertedData,
                  {
                    headers: headersForRequestsWithBodies,
                  }
                )
              } catch (err) {
                if (err instanceof SyntaxError) {
                  theResponse = jsonSyntaxErrorResponse
                } else {
                  throw err
                }
              }
            }
          }
          break
      }

      if (JSON.stringify(theResponse.data).length > 50000) {
        setResponseFromApi({
          status: 200,
          data:
            'The supplied inputs return too much data to display. Please adjust and try again.',
        })
      } else {
        setResponseFromApi(theResponse)
      }

      metricsService.track(API_TEST_RUN, {
        apiName: name,
        isSuccesful: true,
      })
    } catch (err) {
      setResponseFromApi(err.response)
      metricsService.track(API_TEST_RUN, {
        apiName: name,
        isSuccesful: false,
        errorsReturned: err?.response?.data,
      })
    } finally {
      setSendingRequest(false)
    }
  }

  const displayParameterInputs = (): React.ReactNode => {
    return (
      <div className="parameters-container">
        <p className="inputs-header">{t('tryOut.parameters')}</p>
        {apiDetails.parameters?.map(
          (param: IRestParameters, index) =>
            (param.in === 'query' || param.in === 'path') && (
              <div key={index} className="input-outer-box">
                <div className="label-box">
                  <div className="param-name">
                    <span>{param.name}</span>
                    {param.required && <span className="required">*</span>}
                  </div>
                  <span className="param-type">
                    {determineParamType(param)}
                  </span>
                </div>
                <Input
                  name={param.name}
                  placeholder={
                    param?.example?.toString() ||
                    param.schema?.example?.toString()
                  }
                  onChange={(input: any): void => {
                    const paramsCopy = apiDetails.parameters?.slice(0)
                    const theIndex = paramsCopy?.findIndex(
                      (el) => el.name === param.name
                    )
                    paramsCopy![theIndex!].value = input.target.value

                    setApiDetails({
                      ...apiDetails,
                      parameters: paramsCopy,
                    })

                    if (!apiDetails.requestBody) {
                      setCurlStatement(
                        assembleCurlStatement(apiDetails, tokenFromInput)
                      )
                    } else {
                      validateRequestBodyJson(
                        apiDetails.requestBody as any,
                        apiDetails,
                        tokenFromInput
                      )
                    }
                  }}
                  onKeyDown={(arg: any): void => {
                    if (arg.keyCode === 13) {
                      runHttpRequest()
                    }
                  }}
                />
              </div>
            )
        )}
      </div>
    )
  }

  const displayRequestBodyArea = (): React.ReactNode => {
    return (
      <>
        <p className="inputs-header">{t('generalDocumentation.requestBody')}</p>
        <div className="md-input-group request-body">
          <textarea
            className="md-input"
            id="textArea"
            name="textArea"
            onChange={(el): void => {
              const newApiDetails = {
                ...apiDetails,
                requestBody: {
                  ...apiDetails.requestBody!,
                  value: el.target.value,
                },
              }

              validateRequestBodyJson(
                el.target.value,
                newApiDetails,
                tokenFromInput
              )

              setApiDetails(newApiDetails)
            }}
            value={apiDetails.requestBody?.value}
            defaultValue={
              JSON.stringify(apiDetails.requestExample, null, 2) || '{}'
            }
          ></textarea>
        </div>
      </>
    )
  }

  const getResponsePlaceholder = (): string => {
    if (sendingRequest) {
      return t('tryOut.noResponseYet')
    } else {
      return `${t('tryOut.noResponseYet')} ${t('tryOut.clickRunButton')}`
    }
  }

  return (
    <>
      <form className="try-out">
        <CodeSnippetHeader
          canCopy={canCopyCurl}
          copyText={curlStatement}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.request')}</span>
          }
        />
        <div
          className={`body ${
            (responseFromApi && responseFromApi.status && 'request-sent') || ''
          } ${apiDetails.requestBody ? 'has-request-body' : ''}`}
        >
          <p className="inputs-header">{t('tryOut.header')}</p>
          <BearerTokenInput
            handleAuthTokenInput={handleAuthTokenInput}
            toggleId="REST"
          />
          <hr className="try-out-divider" />
          {apiDetails.parameters?.length ? displayParameterInputs() : null}
          {apiDetails.requestBody ? displayRequestBodyArea() : null}
        </div>
        <div className="button-container">
          <Button
            onClick={(): Promise<void> => runHttpRequest()}
            ariaLabel="Run HTTP Request"
            color="green"
            size="28"
            loading={sendingRequest}
            className="request-button"
          >
            {t('tryOut.run')}
          </Button>
        </div>
      </form>
      {responseFromApi && responseFromApi.status ? (
        <CodeSnippet
          canCopy
          codeString={JSON.stringify(responseFromApi.data, null, 2)}
          className="has-response"
          language="json"
          headerContentsLeftSide={
            <>
              <ResponseCode code={responseFromApi.status} />
              <span>{t('generalDocumentation.response')}</span>
            </>
          }
        />
      ) : (
        <CodeSnippet
          codeString={`// ${getResponsePlaceholder()}`}
          className="no-response"
          language="javascript"
          canCopy={false}
          headerContentsLeftSide={
            <span>{t('generalDocumentation.response')}</span>
          }
        />
      )}
    </>
  )
}

export default RestTryOut
