import { graphql } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Parameters from '../../components/Parameters'
import SchemaObjectTable from '../../components/SchemaObjectTable'
import StatusCodeTable from '../../components/StatusCodeTable'
import TryOutAndSampleContainer from '../../components/TryOutAndSampleContainer'
import RestTryOut from '../../components/TryOutAndSampleContainer/RestTryOut'
import SampleContainer from '../../components/TryOutAndSampleContainer/SampleContainer'
import { useApiVersions } from '../../hooks/useApiVersions'
import { IRest } from '../../interfaces/restInterfaces'
import ApiLayout from '../../layouts/ApiLayout'
import { getParams } from '../../utils/documentation.utils'
export interface IRestDocumentationProps {
  pageContext: {
    name: string
    resourceName: string
    version: number
  }
  data: {
    strapiRests: IRest
  }
}

export const query = graphql`
  query RestQuery($name: String!, $version: Int!) {
    strapiRests(name: { eq: $name }, version: { eq: $version }) {
      data
    }
  }
`

const RestDocumentation: React.FC<IRestDocumentationProps> = ({
  pageContext,
  data,
}): JSX.Element => {
  const { t } = useTranslation()
  const {
    name: apiName,
    version: apiCurrentVersion,
    resourceName,
  } = pageContext
  const { data: apiData } = data.strapiRests
  const apiVersionNums = useApiVersions(apiName, 'rests')

  const pathParameters = getParams(apiData.parameters, 'path')
  const queryParameters = getParams(apiData.parameters, 'query')

  const leftSideContents = (
    <>
      {pathParameters?.length ? (
        <Parameters
          parameters={pathParameters}
          headingName={t('generalDocumentation.pathParameters')}
        />
      ) : null}
      {queryParameters?.length ? (
        <Parameters
          parameters={queryParameters}
          headingName={t('generalDocumentation.requestParameters')}
        />
      ) : null}
      {apiData.requestBody && (
        <SchemaObjectTable
          name={t('generalDocumentation.requestBody')}
          schemaObject={apiData.requestBody}
          markRequiredItems={true}
        />
      )}
      {apiData.responseBody && (
        <SchemaObjectTable
          name={t('generalDocumentation.responseAttributes')}
          schemaObject={apiData.responseBody}
          markRequiredItems={false}
        />
      )}
      {apiData.responseCodes && (
        <StatusCodeTable statusCodes={apiData.responseCodes} />
      )}
    </>
  )

  const rightSideContents = (
    <TryOutAndSampleContainer
      tabHeadings={[t('generalDocumentation.sampleCode'), t('tryOut.tryOut')]}
      tabPanes={[
        <SampleContainer apiDetails={apiData} key={0} />,
        <RestTryOut api={apiData} name={apiName} key={1} />,
      ]}
    />
  )

  return (
    <ApiLayout
      apiName={apiName}
      resourceName={resourceName}
      description={apiData.description}
      endpoint={apiData.endpoint}
      badgeType={apiData.httpVerb}
      leftSideContents={leftSideContents}
      rightSideContents={rightSideContents}
      versions={apiVersionNums}
      currentVersion={apiCurrentVersion}
    />
  )
}

export default RestDocumentation
