import React from 'react'
import { IRestParameters } from '../../interfaces/restInterfaces'
import { makeArrayTypeString } from '../../utils/general.utils'
import PropertyListItem from '../PropertyListItem'
import {
  Accordion,
  AccordionContent,
  AccordionGroup,
  AccordionHeader,
} from '@momentum-ui/react'
interface IParametersProps {
  headingName: string
  parameters: IRestParameters[]
}

const Parameters: React.FC<IParametersProps> = ({
  headingName,
  parameters,
}) => {
  // Sort required params to top of the list
  const activeProps = Array.from(parameters.keys())
  parameters?.sort((a, b) => {
    if (a.required && b.required) {
      return 0
    } else if (a.required) {
      return -1
    } else if (b.required) {
      return 1
    } else {
      return 0
    }
  })

  return (
    <Accordion
      multipleVisible
      initialActive={activeProps}
      initialActiveFocus={false}
      className="parameters"
    >
      <AccordionGroup className="parameters">
        <AccordionHeader className="parameter-header">
          <h2>{headingName}</h2>
        </AccordionHeader>
        <AccordionContent className="inner-table">
          <ul>
            {parameters.map((parameter, index) => {
              const { name, required } = parameter
              const example = parameter.schema?.example || parameter.example
              const description =
                parameter.schema?.description || parameter.description
              return (
                <PropertyListItem
                  name={name}
                  description={description}
                  type={
                    makeArrayTypeString(parameter.schema?.items?.type) ||
                    parameter.schema?.type
                  }
                  example={example}
                  key={index}
                  required={required}
                  format={
                    makeArrayTypeString(parameter.schema?.items?.format) ||
                    parameter.schema?.format
                  }
                />
              )
            })}
          </ul>
        </AccordionContent>
      </AccordionGroup>
    </Accordion>
  )
}

export default Parameters
