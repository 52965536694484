import { IRestParameters } from '../interfaces/restInterfaces'

export const getParams = (
  params: IRestParameters[] | undefined,
  type: string
): IRestParameters[] | undefined => {
  if (params) {
    return params.filter((param) => param.in === type)
  }
}
